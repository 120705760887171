import React, { useRef } from "react";
import "./Sosmed.scss";
import { ReactComponent as Checklist } from "../../media/check-list.svg";
import { ReactComponent as ArrowUp } from "../../media/arrowrightup.svg";
import star from "../../media/workshowcase/glowing-star-emoji.png";
import intipesonalogo from "../../media/portfolio/logo intipesona.png";
import catalog from "../../media/portfolio/catalog-ntipesona.webp";
import proflightfav from "../../media/portfolio/proflight-fav.png";
import proflightlogo from "../../media/portfolio/proflight-logo.png";
import mockupphone from "../../media/portfolio/mockup-phone.png";
import { ReactComponent as Like } from "../../media/portfolio/like.svg";
import { ReactComponent as Comment } from "../../media/portfolio/comment.svg";
import { ReactComponent as Share } from "../../media/portfolio/share.svg";
import { ReactComponent as Save } from "../../media/portfolio/save.svg";
import Divider from "./Divider";
import Slider from "react-slick";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";
const itempoint = [
  "Website Keren",
  "Aktivasi Brand Penuh Gaya",
  "Desain Super Fresh",
  "Optimasi Maksimal",
  "Support Terpercaya",
];
const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const Sosmed = () => {
  const sliderRef = useRef(null);

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <div className="sosmedcont">
      <Divider />
      <div className="center">
        <div className="herocenterhead">
          <div className="lefthead">
            <div className="wrapper">
              <span>Craxion Studio</span>
              <span>//</span>
            </div>
          </div>
          <div className="righthead">
            <div className="wrapper">
              <span>Layanan</span>
              <span>02</span>
            </div>
          </div>
        </div>
        <div className="webdevtitle">
          GUNCANG DUNIA DIGITAL DENGAN AKTIVASI BRAND YANG BIKIN SEMUA
          NGOMONGIN!
        </div>
        <div className="webdevsub">
          Pilih Paket Aktivasi Seru Kami Mulai dari 4JUTA-AN! Dapatkan konten
          medsos yang catchy, desain grafis yang bikin semua melongo, dan
          strategi yang out of the box. Yuk, jangan sampai ketinggalan! Hubungi
          kami dan jadikan brand kamu bintang di dunia digital!
        </div>
        <div className="pricebox">
          <div className="boxdes">
            <div className="boxtitle">
              Bawa Brand Kamu ke Puncak Kepopuleran!
            </div>
            <div className="boxsub">
              Harga Gila, Kualitas Nggak Ada Lawan! Dapatkan konten medsos yang
              bikin semua melongo dan desain grafis yang out of the box.
            </div>
          </div>
          <div className="boxpoint">
            <div className="pointtitle">Apa yang Anda dapatkan:</div>
            {itempoint.map((item, index) => (
              <div className="point" key={index}>
                <Checklist fill="#000000" />
                &nbsp;{item}
              </div>
            ))}
          </div>
          <div className="boxprice">
            <div className="pricetitle">Mulai dari</div>
            <div className="price">
              Rp
              <span>4.000.000</span>
            </div>
            <div className="pricebutton">
              <button className="buttonwa">
                Pilih Paket Ini &nbsp; <ArrowUp width={15} height={15} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="head-sosmed">
        <div className="headleft">
          <div className="label">
            <img src={star} alt="star" className="star" />
            Karya Terkece Kita Part 1
          </div>
        </div>
        <div className="headright">
          <h4 className="title">KOLEKSI KARYA KITA</h4>
        </div>
      </div>
      <div className="showcase-content">
        <div className="showcase-content-head">
          <div className="showcase-content-head-title">
            Cek karya seru dari layanan Web Development kami yang penuh
            kreativitas dan inovasi ini.
          </div>
          <div className="showcase-content-arrow">
            <div className="left-arrow" onClick={handlePrevClick}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#ffff"
                className="inner-left-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#ffff"
                className="outer-left-arrow
              "
              />
            </div>
            <div className="right-arrow" onClick={handleNextClick}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#ffff"
                className="inner-right-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#1A1B24"
                className="outer-right-arrow
              "
              />
            </div>
          </div>
        </div>
        <div className="slider">
          <Slider {...settings} ref={sliderRef}>
            <div className="card card-physical">
              <div className="card-top">
                <img src={intipesonalogo} className="image-top-physical" />
              </div>
              <div className="card-body">
                <img src={catalog} alt="" className="image-body-physical " />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <li>Katalog Produk</li>
                  <li>PT. Inti Pesona Lestari</li>
                </div>
                <div className="card-info-physical-2">
                  <li>12 Feb 2024</li>
                  <li>intipesonalestari.com</li>
                </div>
              </div>
            </div>
            <div className="card card-physical">
              <div className="card-top">
                <img src={intipesonalogo} className="image-top-physical" />
              </div>
              <div className="card-body">
                <img src={catalog} alt="" className="image-body-physical " />
              </div>
              <div className="card-bottom">
                <div className="card-info-physical-1">
                  <li>Katalog Produk</li>
                  <li>PT. Inti Pesona Lestari</li>
                </div>
                <div className="card-info-physical-2">
                  <li>12 Feb 2024</li>
                  <li>intipesonalestari.com</li>
                </div>
              </div>
            </div>
            <div className="card card-digital">
              <div className="card-top">
                <img src={proflightfav} className="image-top-digital" />
                <div>
                  <li>proflight_indonesia</li>
                  <li>Proflight Indonesia Official</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li>3 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img src={proflightlogo} alt="" className="image-body-logo" />
                </div>
                <img src={mockupphone} alt="" className="image-body-digital" />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  716K
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>
                  7.686K
                </div>
                <div className="card-info-digital">
                  <Share></Share>
                  47K
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  78.2K
                </div>
              </div>
            </div>
            <div className="card card-digital">
              <div className="card-top">
                <img src={proflightfav} className="image-top-digital" />
                <div>
                  <li>proflight_indonesia</li>
                  <li>Proflight Indonesia Official</li>
                </div>
              </div>
              <div className="card-body">
                <div className="info-view">
                  <div>
                    <li>3 Ribu Penonton!</li>
                    <li>Pemutaran Konten</li>
                  </div>
                  <img src={proflightlogo} alt="" className="image-body-logo" />
                </div>
                <img src={mockupphone} alt="" className="image-body-digital" />
              </div>
              <div className="card-bottom">
                <div className="card-info-digital">
                  <Like></Like>
                  716K
                </div>
                <div className="card-info-digital">
                  <Comment></Comment>
                  7.686K
                </div>
                <div className="card-info-digital">
                  <Share></Share>
                  47K
                </div>
                <div className="card-info-digital">
                  <Save></Save>
                  78.2K
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="pricebutton">
        <button className="buttonwa">
          Lihat Lainnya &nbsp; <ArrowUp width={15} height={15} />
        </button>
      </div>
    </div>
  );
};

export default Sosmed;
