import React, { useEffect, useRef, useState } from "react";
import "./Webdev.scss";
import { ReactComponent as Checklist } from "../../media/check-list.svg";
import { ReactComponent as ArrowUp } from "../../media/arrowrightup.svg";
import Slider from "react-slick";
import star from "../../media/workshowcase/glowing-star-emoji.png";
import lovana from "../../media/servicescard/experienced-design.webp";
import nticosme from "../../media/servicescard/web-development.webp";
import paiumroh from "../../media/paiumroh.webp";
import paipai from "../../media/paipai.webp";
import perfolova from "../../media/workshowcase/perfo-lovana.png";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";
import { ReactComponent as Arrowbtn } from "../../media/arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Divider from "./Divider";
const Webdev = () => {
  const itempoint = [
    "Website Keren",
    "Aktivasi Brand Penuh Gaya",
    "Desain Super Fresh",
    "Optimasi Maksimal",
    "Support Terpercaya",
  ];
  const slidesData = [
    {
      img: `${lovana}`,
      title: "Lovanastory",
      performance: `${perfolova}`,
      description:
        "Lovanastory punya perawatan tubuh Halal yang asyik banget! Ada websitenya yang keren abis buat promosi produk Lovana dan dapetin info lengkapnya disana. Biar kamu makin kekinian dan PD abis!",
      tags: "Web Development",
      link: "#",
    },
    {
      img: `${nticosme}`,
      title: "PT. Inticosmetic Lestari",
      performance: `${perfolova}`,
      description:
        "Inticosmetic Lestari, website kece dengan produk perawatan tubuh premium. Fokus pada kesehatan, kecantikan, dan keragaman. Temukan info terbaru, desain user-friendly. Eksplorasi dan pilih produk terbaik!",
      tags: "Web Development",
      link: "#",
    },
    {
      img: `${paiumroh}`,
      title: "Pai Umroh",
      performance: `${perfolova}`,
      description:
        "Pai Umroh punya paket seru buat umroh dan jalan-jalan ke negara-negara keren. Ada jadwal keberangkatan yang oke banget, harga yang bersaing, dan bisa bantu urusan visa kalian juga.",
      tags: "Web Development",
      link: "#",
    },
    {
      img: `${paipai}`,
      title: "Paipai Travel",
      performance: `${perfolova}`,
      description:
        "Lovanastory punya perawatan tubuh Halal yang asyik banget! Ada websitenya yang keren abis buat promosi produk Lovana dan dapetin info lengkapnya disana. Biar kamu makin kekinian dan PD abis!",
      tags: "Web Development",
      link: "#",
    },
  ];
  const sliderRefs = useRef([]);

  // Function to add refs
  const addToRefs = (el) => {
    if (el && !sliderRefs.current.includes(el)) {
      sliderRefs.current.push(el);
    }
  };

  // Function to navigate to the next slide in all sliders
  const next = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickNext();
    });
  };

  // Function to navigate to the previous slide in all sliders
  const previous = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickPrev();
    });
  };
  const [settings, setSliderSettings] = useState({
    swipe: false,
    dots: false,
    infinite: true,
    arrows: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false, // No fade by default
    responsive: [
      {
        breakpoint: 768,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          fade: false,
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {
        setSliderSettings((prevSettings) => ({
          ...prevSettings,
          fade: true, // Enable fade for 1024px and below
        }));
      } else {
        setSliderSettings((prevSettings) => ({
          ...prevSettings,
          fade: false, // Disable fade for larger screens
        }));
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on component mount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="webdevcont">
      <Divider />
      <div className="center">
        <div className="herocenterhead">
          <div className="lefthead">
            <div className="wrapper">
              <span>Craxion Studio</span>
              <span>//</span>
            </div>
          </div>
          <div className="righthead">
            <div className="wrapper">
              <span>Layanan</span>
              <span>01</span>
            </div>
          </div>
        </div>
        <div className="webdevtitle">
          GUNCANG DUNIA DIGITALMU DENGAN WEB KEREN YANG MEMIKAT!
        </div>
        <div className="webdevsub">
          Mulai dari 4JUTA-AN, dapatkan website impianmu yang siap guncang
          pasar! Paket lengkap dengan desain keren, pengembangan responsif, dan
          optimasi SEO. Hubungi kami sekarang dan wujudkan websitemu tanpa harus
          merogoh kocek dalam-dalam!
        </div>
        <div className="pricebox">
          <div className="boxdes">
            <div className="boxtitle">
              Wujudkan Website Impianmu dan Bikin Brand Kamu Jadi Bintang!
            </div>
            <div className="boxsub">
              Saatnya bawa bisnis kamu terbang tinggi! Paket spesial dengan
              HARGA JOSS dan Kualitas yang NGGAK ADA DUANYA!
            </div>
          </div>
          <div className="boxpoint">
            <div className="pointtitle">Apa yang Anda dapatkan:</div>
            {itempoint.map((item, index) => (
              <div className="point" key={index}>
                <Checklist fill="#ffff" />
                &nbsp;{item}
              </div>
            ))}
          </div>
          <div className="boxprice">
            <div className="pricetitle">Mulai dari</div>
            <div className="price">
              Rp
              <span>4.000.000</span>
            </div>
            <div className="pricebutton">
              <button className="buttonwa">
                Pilih Paket Ini &nbsp; <ArrowUp width={15} height={15} />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="head-webdev">
        <div className="headleft">
          <div className="label">
            <img src={star} alt="star" className="star" />
            Karya Terkece Kita Part 1
          </div>
        </div>
        <div className="headright">
          <h4 className="title">KOLEKSI KARYA KITA</h4>
        </div>
      </div>
      <div className="showcase-content-webdev">
        <div className="showcase-content-head">
          <div className="showcase-content-head-title">
            Cek karya seru dari layanan Web Development kami yang penuh
            kreativitas dan inovasi ini.
          </div>
          <div className="showcase-content-arrow">
            <div className="left-arrow" onClick={next}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#000000"
                className="inner-left-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#000000"
                className="outer-left-arrow
              "
              />
            </div>
            <div className="right-arrow" onClick={previous}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#000000"
                className="inner-right-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#000000"
                className="outer-right-arrow
              "
              />
            </div>
          </div>
        </div>

        <div className="slider">
          <div className="slider-item1">
            <Slider ref={addToRefs} {...settings} initialSlide={0}>
              {slidesData.map((slide, index) => (
                <img src={slide.img} key={index} alt={slide.title} />
              ))}
            </Slider>
          </div>
          <div className="slider-item2">
            <Slider ref={addToRefs} {...settings} initialSlide={1}>
              {slidesData.map((slide, index) => (
                <div className="slider-container" key={index}>
                  <img
                    src={slide.img}
                    alt={slide.title}
                    className="background-img"
                  />
                  <div className="slider-content">
                    <img
                      src={slide.performance}
                      alt={slide.title}
                      className="rating"
                    />
                    <div className="slider-content-wrapper">
                      <div className="slider-content-label">
                        <h5 className="title">{slide.title}</h5>
                        <p className="description">{slide.description}</p>
                        <p className="tags">{slide.tags}</p>
                      </div>
                      <button className="read-more">
                        Baca Selengkapnya
                        <div>
                          <Arrowbtn
                            width={40}
                            height={40}
                            fill="#001827"
                            className="primary-arrow"
                          />
                          <Arrowbtn
                            width={40}
                            height={40}
                            fill="#001827"
                            className="secondary-arrow"
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider-item3">
            <Slider ref={addToRefs} {...settings} initialSlide={2}>
              {slidesData.map((slide, index) => (
                <div>
                  <img
                    src={slide.img}
                    alt={slide.title}
                    className="background-img"
                  />
                  <div className="slider-content">
                    <img
                      src={slide.performance}
                      alt={slide.title}
                      className="rating"
                    />
                    <div className="slider-content-wrapper">
                      <div className="slider-content-label">
                        <h5 className="title">{slide.title}</h5>
                        <p className="description">{slide.description}</p>
                        <p className="tags">{slide.tags}</p>
                      </div>
                      <button className="read-more">
                        Baca Selengkapnya
                        <div>
                          <Arrowbtn
                            width={40}
                            height={40}
                            fill="#001827"
                            className="primary-arrow"
                          />
                          <Arrowbtn
                            width={40}
                            height={40}
                            fill="#001827"
                            className="secondary-arrow"
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="btn-work">
          <button>
            Lihat Lainnya
            <div>
              <Arrowbtn
                width={40}
                height={40}
                fill="#001827"
                className="primary-arrow"
              />
              <Arrowbtn
                width={40}
                height={40}
                fill="#001827"
                className="secondary-arrow"
              />
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Webdev;
