import React from "react";
import "./Footer.scss";
import { ReactComponent as Arrowbtn } from "../../media/arrow-right.svg";
import { menuItems, services, contact } from "../../component/sitemap-data";
import Marquee from "react-fast-marquee";

const Footer = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer id="footer" className="footer-container">
      <Marquee speed={150} delay={2} className="footer-marquee">
        <h2>
          Craxion Studio. Bikin proyek keren dan beragam buat semua level
          ambisi.
        </h2>
      </Marquee>

      <div className="footer-content">
        <div className="footer-site">
          <div className="footer-site-map">
            <p>Peta Situs</p>
            <ol className="footer-links">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <a href={item.link} className="footer-item">
                    <span className="inner-link">
                      <span className="num-link">{item.id}</span>
                      <span> {item.name}</span>
                    </span>
                    <div className="outer-link">
                      <Arrowbtn width={15} height={15} fill="#001827" />
                      {item.name}
                    </div>
                  </a>
                </li>
              ))}
            </ol>
          </div>
          <div className="footer-site-map">
            <p>Layanan</p>
            <ol className="footer-links">
              {services.map((item, index) => (
                <li key={index}>
                  <a href={item.link} className="footer-item">
                    <span className="inner-link">
                      <span className="num-link">{item.id}</span>
                      <span> {item.name}</span>
                    </span>
                    <div className="outer-link">
                      <Arrowbtn width={15} height={15} fill="#001827" />
                      {item.name}
                    </div>
                  </a>
                </li>
              ))}
            </ol>
          </div>
          <div className="footer-site-map">
            <p>Peta Situs</p>
            <ol className="footer-links">
              {contact.map((item, index) => (
                <li key={index}>
                  <a href={item.link} className="footer-item">
                    <span className="inner-link">
                      <span className="num-link">{item.id}</span>
                      <span> {item.name}</span>
                    </span>
                    <div className="outer-link">
                      <Arrowbtn width={15} height={15} fill="#001827" />
                      {item.name}
                    </div>
                  </a>
                </li>
              ))}
            </ol>
          </div>

          <div className="footer-site-button">
            <button onClick={handleClick}>
              Kembali Ke Atas
              <div>
                <Arrowbtn
                  width={40}
                  height={40}
                  fill="#001827"
                  className="primary-arrow"
                />
                <Arrowbtn
                  width={40}
                  height={40}
                  fill="#001827"
                  className="secondary-arrow"
                />
              </div>
            </button>
          </div>
        </div>
        <div className="footer-bottom">
          <div>©2024</div>
          <div>Craxion Studio</div>
          <div className="email-media">
            Untuk pertanyaan media
            <a href="mailto:craxionstudio@gmail.com">craxionstudio@gmail.com</a>
          </div>
          <div>
            Dibangun dengan <span>C.I.N.T.A</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
