import React from "react";
import "./Testimoni.scss";
import handsimg from "../../media/testimoni/raising-hands-emoji.png";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";

const Testimoni = () => {
  return (
    <section className="testimoni-section">
      <div className="label">
        <img src={handsimg} alt="compass" />
        Kata Mereka Tentang Craxion
      </div>
      <div>
        <h4 className="title">Feedback Keren dari Klien Kami</h4>
      </div>
      <div className="slider">
        <div className="slider1">
          <div className="sliderspacing"></div>
        </div>
        <div className="arrowspace">
          <div className="left-arrow">
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="inner-left-arrow
              "
            />
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="outer-left-arrow
              "
            />
          </div>
        </div>

        <div className="slider2">
          <div className="sliderspacing">
            <div className="slidertext">
              <div className="testimoni">
                During the project, Craxion team has provided us with a strongly
                capable technical team that help us achieve our objective. Thank
                You Craxion team.
              </div>
              <div className="footertestimoni">
                <div className="person">
                  <div className="personname">Agung Syahputra</div>
                  <div className="persontitle">
                    CEO of PT. Baswara Kreasi Digital
                  </div>
                </div>
                <div className="logo"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="arrowspace">
          <div className="right-arrow">
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="inner-right-arrow
              "
            />
            <ArrowSlider
              width={15}
              height={15}
              fill="#1A1B24"
              className="outer-right-arrow
              "
            />
          </div>
        </div>

        <div className="slider3">
          <div className="sliderspacing"></div>
        </div>
      </div>
    </section>
  );
};

export default Testimoni;
