import React from "react";
import Hero from "../../section/Hero/Hero";
import "./Home.scss";
import About from "../../section/About/About";

import Webdev from "../../section/Webdev/Webdev";
import Sosmed from "../../section/Sosmed/Sosmed";
import Appdev from "../../section/Appdev/Appdev";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Blog from "../../section/Blog/Blog";
import Testimoni from "../../section/testimoni/Testimoni";
const Home = () => {
  return (
    <div className="app">
      <Hero />
      <About />

      <Webdev />
      <Sosmed />
      <Appdev />
      <Blog />
      <Testimoni />
    </div>
  );
};

export default Home;
