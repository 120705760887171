import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../navbar/Navbar.scss";
import logo from "../../media/logo.png";
import { ReactComponent as Arrowbtn } from "../../media/arrow.svg";
import { menuItems } from "../../component/sitemap-data.js";
import { ReactComponent as Plus } from "../../media/Vector.svg";
const Navbar = () => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <div className="navbar-container">
        <div className="logo">
          <a href="/">
            <img src={logo} alt="craxion" width="128px" />
          </a>
          <div className="logo-desc">
            Bikin proyek keren dan beragam buat semua level ambisi
          </div>
        </div>
        <ul className={isOpen ? "nav-links active" : "nav-links"}>
          <div className="nav-menu">
            {menuItems.map((item, index) => (
              <li key={index}>
                <a href={item.link}>
                  <div
                    className={
                      "nav-item" +
                      (currentPath === item.link ? " nav-item-active" : "")
                    }
                  >
                    <div className="inner-item">{item.name}</div>
                    <div className="outer-item">{item.name}</div>
                  </div>
                </a>
              </li>
            ))}
          </div>
          <div className="contact-btn">
            <button className="inner-btn">
              Hubungi Kami <Arrowbtn width={23} height={23} fill="#12131C" />
            </button>
            <button className="outer-btn">
              Hubungi Kami <Arrowbtn width={23} height={23} fill="#ffffff" />
            </button>
          </div>
        </ul>

        <button className="menu-icon" onClick={toggleMenu}>
          <i className={isOpen ? "fas fa-times" : "fas fa-bars"}>Menu &nbsp;</i>
          <Plus />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
