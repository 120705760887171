const menuItems = [
  { id: 1, name: "Beranda", link: "/" },
  { id: 2, name: "Web Development", link: "/Webdevelopment" },
  { id: 3, name: "Portofolio", link: "#" },
  { id: 4, name: "Layanan", link: "#" },
  { id: 5, name: "Harga", link: "#" },
  { id: 6, name: "CraxZine", link: "#" },
];

const services = [
  { id: 1, name: "Web Development", link: "#" },
  { id: 2, name: "Brand Activation", link: "#" },
];

const contact = [
  { id: 1, name: "Instagram", link: "#" },
  { id: 2, name: "Tiktok", link: "#" },
  { id: 3, name: "Whatsapp", link: "#" },
  { id: 4, name: "Linkedin", link: "#" },
  { id: 5, name: "Dribbble", link: "#" },
  { id: 6, name: "Behance", link: "#" },
];
export { menuItems, services, contact };
