import React from "react";
import { ReactComponent as Arrowbtn } from "../../media/arrow.svg";
import "./Hero.scss";
import waving from "../../media/welcomehome/waving-hand-emoji.png";

const Hero = () => {
  return (
    <section className="head-container">
      <div className="welcome-container">
        <div className="label">
          <img src={waving} alt="waving" />
          <div id="waving"></div>
        </div>
        <h2 className="headline">DARI KHAYALAN KE REALITAS DIGITAL</h2>
        <p className="description">
          Yuk, bikin gebrakan digital bareng! Dari pengembangan web, aplikasi
          kece, sampai manajemen media sosial yang bikin melongo. Kreativitas
          kita ketemu teknologi, siap-siap bikin dunia melirik! Hubungi kami dan
          wujudkan ide gilamu!
        </p>
        <div className="buttondiv">
          <button className="btn-welcome">
            Cari Tahu Sekarang
            <div>
              <Arrowbtn
                width={23}
                height={23}
                fill="#001827"
                className="primary-arrow"
              />
              <Arrowbtn
                width={23}
                height={23}
                fill="#001827"
                className="secondary-arrow"
              />
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
