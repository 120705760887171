import React, { useRef, useState } from "react";
import "./Blog.scss";

import chartimg from "../../media/blogs/chart-increasing-emoji.png";
import star from "../../media/workshowcase/glowing-star-emoji.png";
import lovana from "../../media/servicescard/experienced-design.webp";
import nticosme from "../../media/servicescard/web-development.webp";
import paiumroh from "../../media/paiumroh.webp";
import paipai from "../../media/paipai.webp";
import { ReactComponent as Arrowbtn } from "../../media/arrow.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as ArrowSlider } from "../../media/arrow-right.svg";
const Blog = () => {
  const sliderRefs = useRef([]);

  // Function to add refs
  const addToRefs = (el) => {
    if (el && !sliderRefs.current.includes(el)) {
      sliderRefs.current.push(el);
    }
  };

  // Function to navigate to the next slide in all sliders
  const next = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickNext();
    });
  };

  // Function to navigate to the previous slide in all sliders
  const previous = () => {
    sliderRefs.current.forEach((slider) => {
      if (slider) slider.slickPrev();
    });
  };
  const settings = {
    swipe: false,
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const slidesData = [
    {
      img: `${lovana}`,
      title: "Lovanastory",
      description:
        "Lovanastory punya perawatan tubuh Halal yang asyik banget! Ada websitenya yang keren abis buat promosi produk Lovana dan dapetin info lengkapnya disana. Biar kamu makin kekinian dan PD abis!",
      tags: "Web Development",
    },
    {
      img: `${nticosme}`,
      title: "PT. Inticosmetic Lestari",
      description:
        "Inticosmetic Lestari, website kece dengan produk perawatan tubuh premium. Fokus pada kesehatan, kecantikan, dan keragaman. Temukan info terbaru, desain user-friendly. Eksplorasi dan pilih produk terbaik!",
      tags: "Web Development",
    },
    {
      img: `${paiumroh}`,
      title: "Pai Umroh",
      description:
        "Pai Umroh punya paket seru buat umroh dan jalan-jalan ke negara-negara keren. Ada jadwal keberangkatan yang oke banget, harga yang bersaing, dan bisa bantu urusan visa kalian juga.",
      tags: "Web Development",
    },
    {
      img: `${paipai}`,
      title: "Paipai Travel",
      description:
        "Lovanastory punya perawatan tubuh Halal yang asyik banget! Ada websitenya yang keren abis buat promosi produk Lovana dan dapetin info lengkapnya disana. Biar kamu makin kekinian dan PD abis!",
      tags: "Web Development",
    },
  ];
  return (
    <section className="blogs-section">
      <div className="head">
        <div className="label">
          <img src={chartimg} alt="star" />
          Konten Fresh Terbaru
        </div>
        <div className="titlem">
          <h4 className="title">CRAXZINE</h4>
        </div>
      </div>
      <div className="showcase-content">
        <div className="showcase-content-head">
          <div>
            Temukan konten keren dan ide-ide fresh yang bikin kamu terinspirasi
            disini!
          </div>
          <div className="showcase-content-arrow">
            <div className="left-arrow" onClick={next}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#1A1B24"
                className="inner-left-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#1A1B24"
                className="outer-left-arrow
              "
              />
            </div>
            <div className="right-arrow" onClick={previous}>
              <ArrowSlider
                width={15}
                height={15}
                fill="#1A1B24"
                className="inner-right-arrow
              "
              />
              <ArrowSlider
                width={15}
                height={15}
                fill="#1A1B24"
                className="outer-right-arrow
              "
              />
            </div>
          </div>
        </div>
        <div className="slider">
          <div className="slider-item1">
            <Slider ref={addToRefs} {...settings} initialSlide={0}>
              {slidesData.map((slide, index) => (
                <img src={slide.img} key={index} alt={slide.title} />
              ))}
            </Slider>
          </div>
          <div className="slider-item2">
            <Slider ref={addToRefs} {...settings} initialSlide={1}>
              {slidesData.map((slide, index) => (
                <div className="slider-container" key={index}>
                  <img
                    src={slide.img}
                    alt={slide.title}
                    className="background-img"
                  />
                  <div className="slider-content">
                    <div className="slider-content-wrapper">
                      <div className="slider-content-label">
                        <h5 className="title">{slide.title}</h5>
                        <p className="description">{slide.description}</p>
                        <p className="tags">{slide.tags}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider-item3">
            <Slider ref={addToRefs} {...settings} initialSlide={2}>
              {slidesData.map((slide, index) => (
                <div className="slider-container" key={index}>
                  <img
                    src={slide.img}
                    alt={slide.title}
                    className="background-img"
                  />
                  <div className="slider-content">
                    <div className="slider-content-wrapper">
                      <div className="slider-content-label">
                        <h5 className="title">{slide.title}</h5>
                        <p className="description">{slide.description}</p>
                        <p className="tags">{slide.tags}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="btn-work">
          <button>
            Lihat Lainnya
            <div>
              <Arrowbtn
                width={40}
                height={40}
                fill="#001827"
                className="primary-arrow"
              />
              <Arrowbtn
                width={40}
                height={40}
                fill="#001827"
                className="secondary-arrow"
              />
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Blog;
