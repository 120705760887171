import React, { useEffect, useRef, useState } from "react";
import "./Appdev.scss";
import Divider from "./Divider";
import { ReactComponent as Globe } from "../../media/globe.svg";
import Slider from "react-slick";
const Appdev = () => {
  const [button1Active, setButton1Active] = useState(false);
  const [button2Active, setButton2Active] = useState(false);
  const [button3Active, setButton3Active] = useState(false);

  const handleButton1Click = () => {
    setButton1Active(true);
    setButton2Active(false);
    setButton3Active(false);
  };

  const handleButton2Click = () => {
    setButton1Active(false);
    setButton2Active(true);
    setButton3Active(false);
  };
  const handleButton3Click = () => {
    setButton3Active(true);
    setButton1Active(false);
    setButton2Active(false);
  };
  const [sliderEnabled, setSliderEnabled] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      // Adjust the breakpoint as needed
      setSliderEnabled(width <= 768); // Disable for tablet and desktop (width > 768)
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const slideritem = [
    {
      title: "WEB APPS YANG MELESAT!",
      subtitle:
        "Bingung cara bikin website yang menarik? Ayo, kolaborasi sama kami dan wujudkan ide-ide gila kamu jadi kenyataan! Hubungi kami sekarang!",
      buttontext: "Web Apps",
      text: "Buat website yang interaktif dan responsif dengan aplikasi web custom kami, siap untuk meningkatkan visibilitas dan interaksi bisnismu!",
    },
    {
      title: "WEB APPS YANG MELESAT!",
      subtitle:
        "Bingung cara bikin website yang menarik? Ayo, kolaborasi sama kami dan wujudkan ide-ide gila kamu jadi kenyataan! Hubungi kami sekarang!",
      buttontext: "Mobile Apps",
      text: " Kembangkan aplikasi mobile yang menarik dan mudah digunakan, untuk menjangkau pelanggan di genggaman tangan mereka dan meningkatkan loyalitas!",
    },
    {
      title: "WEB APPS YANG MELESAT!",
      subtitle:
        "Bingung cara bikin website yang menarik? Ayo, kolaborasi sama kami dan wujudkan ide-ide gila kamu jadi kenyataan! Hubungi kami sekarang!",
      buttontext: "Desktop Apps",
      text: "Rancang aplikasi desktop yang powerful dan intuitif, untuk memberikan pengalaman pengguna terbaik dan mendukung produktivitas bisnismu!",
    },
  ];
  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const settingsbutton = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    disabled: !sliderEnabled,
  };
  function goToSlide1() {
    slider.current.slickGoTo(0); // Go to slide 1 (index 0)
  }

  function goToSlide2() {
    slider.current.slickGoTo(1); // Go to slide 2 (index 1)
  }

  function goToSlide3() {
    slider.current.slickGoTo(2); // Go to slide 3 (index 2)
  }

  const slider = useRef(null);
  return (
    <div className="appdevcont">
      <Divider />
      <div className="center">
        <div className="herocenterhead">
          <div className="lefthead">
            <div className="wrapper">
              <span>Craxion Studio</span>
              <span>//</span>
            </div>
          </div>
          <div className="righthead">
            <div className="wrapper">
              <span>Layanan</span>
              <span>03</span>
            </div>
          </div>
        </div>
        <div className="webdevtitle">
          WUJUDKAN APLIKASI CIAMIK YANG BIKIN BISNISMU MELEJIT!
        </div>
        <div className="webdevsub">
          Siap untuk jadi bintang di dunia digital? Tim kami bakal bantu kamu
          bikin aplikasi custom yang nggak hanya kece, tapi juga super
          user-friendly! Ayo, bawa interaksi dengan pelangganmu ke level yang
          lebih seru dan tak terlupakan!
        </div>
        <div className="buttonslider">
          <div className="buttoncontainer">
            <button
              className={`garisbutton ${button1Active ? "active" : ""}`}
              onClick={() => {
                handleButton1Click();
                goToSlide1();
              }}
            ></button>
            <button
              className={`buttonapp ${button1Active ? "active" : ""}`}
              onClick={() => {
                handleButton1Click();
                goToSlide1();
              }}
            >
              <Globe /> &nbsp;Web Apps
            </button>
            <div className="buttontext">
              Buat website yang interaktif dan responsif dengan aplikasi web
              custom kami, siap untuk meningkatkan visibilitas dan interaksi
              bisnismu!
            </div>
          </div>
          <div className="buttoncontainer">
            <button
              className={`garisbutton ${button2Active ? "active" : ""}`}
              onClick={() => {
                handleButton2Click();
                goToSlide2();
              }}
            ></button>
            <button
              className={`buttonapp ${button2Active ? "active" : ""}`}
              onClick={() => {
                handleButton2Click();
                goToSlide2();
              }}
            >
              <Globe /> &nbsp;Mobile Apps
            </button>
            <div className="buttontext">
              Kembangkan aplikasi mobile yang menarik dan mudah digunakan, untuk
              menjangkau pelanggan di genggaman tangan mereka dan meningkatkan
              loyalitas!
            </div>
          </div>
          <div className="buttoncontainer">
            <button
              className={`garisbutton ${button3Active ? "active" : ""}`}
              onClick={() => {
                handleButton3Click();
                goToSlide3();
              }}
            ></button>
            <button
              className={`buttonapp ${button3Active ? "active" : ""}`}
              onClick={() => {
                handleButton3Click();
                goToSlide3();
              }}
            >
              <Globe /> &nbsp;Desktop Apps
            </button>
            <div className="buttontext">
              Rancang aplikasi desktop yang powerful dan intuitif, untuk
              memberikan pengalaman pengguna terbaik dan mendukung produktivitas
              bisnismu!
            </div>
          </div>
        </div>
      </div>
      <div className="slidercenter">
        <div className="sliderapp">
          <Slider {...settings} ref={slider}>
            {slideritem.map((slide, index) => (
              <div>
                <div className="mobilebutton">
                  <button className="buttonapp active">
                    <Globe /> &nbsp;{slide.buttontext}
                  </button>
                  <div className="buttontext">{slide.text}</div>
                </div>
                <div className="slideritem" key={index}>
                  <div className="slidercontent">
                    <div className="title">{slide.title}</div>
                    <div className="subtitle">{slide.subtitle}</div>
                    <button className="buttonwa">Konsultasikan Sekarang</button>
                  </div>
                  <div className="sliderimage"></div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Appdev;
